import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonModalOracle from '../../commonModalOracle';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'addProvisions',
  components: {
    appStrings,
    DatePicker,
    commonModalOracle
  },
  watch: {
    currentPage: function () {
      this.getUpdatedCarryForwardDtlById();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getUpdatedCarryForwardDtlById();
    },
    selectAllCheckBox: function () {
      this.checkUncheckAll();
    }
  },
  props: ['provRowDetails', 'wfProvisionDetails'],
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      updateTotalRows: null,
      updateCurrentPage: 1,
      updatePerPage: commonHelper.perPageRecord,
      loader: false,
      editMode: false,
      showAlert: false,
      successMsg: null,
      responseMsg: '',
      isSuccess: false,
      defaultValue: {
        value: null,
        text: null
      },
      addCarryForwardData: [],
      addCarryForwardFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'vendor_name',
          label: 'Vendor'
        },
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'line_number',
          label: 'PO Line Number'
        },
        {
          key: 'lob'
        },
        {
          key: 'department'
        },
        {
          key: 'cost_centre',
          label: 'Cost Center'
        },
        {
          key: 'account_code'
        },
        {
          key: 'period'
        },
        {
          key: 'amount'
        },
        {
          key: 'cf_amount',
          label: 'Carry Forward Amount'
        },
        // {
        //   key: 'cf_period',
        //   label: 'Carry Forward Period'
        // }
      ],
      selectAllCheckBox: false,
      updateCarryForwardData: [],
      updateCarryForwardFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'vendor_name',
          label: 'Vendor'
        },
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'line_number',
          label: 'PO Line Number'
        },
        {
          key: 'lob'
        },
        {
          key: 'department'
        },
        {
          key: 'cost_centre',
          label: 'Cost Center'
        },
        {
          key: 'account_code'
        },
        {
          key: 'period'
        },
        {
          key: 'amount'
        },
        {
          key: 'cf_amount',
          label: 'Carry Forward Amount'
        },
        // {
        //   key: 'cf_period',
        //   label: 'Carry Forward Period'
        // }
      ],
      legalEntity: {
        text: null,
        value: null
      },
      vendorName: {
        text: null,
        value: null
      },
      poNum: null,
      costCenter: {
        text: null,
        value: null
      },
      concatenatedSegment: {
        text: null,
        value: null
      },
      cfHdrId: 0,
      approvalStatus: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      periodFrom: null,
      periodTo: null,
      department: null,
      lob: null,
      batchName: null,
      cfPeriod: null,
      modalType: null,
      showCommonModal: false,
      vendorCode: null,
      poNumber: null,
      stateCode: null,
      expenseMonth: null
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    periodFrom: { required },
    periodTo: { required }
  },
  mounted() {
    if (this.provRowDetails) {
      // from grid
      this.cfHdrId = this.provRowDetails.cf_hdr_id;
      this.getUpdatedCarryForwardDtlById();
      // this.getProvisonHdrById();
    }
    else {
      this.editMode = true;
    }
    if (this.wfProvisionDetails) {
      // from dashboard
      this.cfHdrId = this.wfProvisionDetails.cf_hdr_id;
      this.getUpdatedCarryForwardDtlById();
      // this.getProvisonHdrById();
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      // from link
      this.cfHdrId = this.urlSummaryData.cf_hdr_id;
      this.getUpdatedCarryForwardDtlById();
      // this.getProvisonHdrById();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditProvisionDetails();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'download') {
          this.getUpdatedCarryForwardDtlById(true);
          // this.loader = true;
          // /**
          //  * @param(payload, 'action name', 'file name')
          //  */
          // const downloadpayload = { ...this.payload };
          // downloadpayload._limit = this.updateTotalRows;
          // this.downloadExcel.downloadData(
          //   downloadpayload,
          //   'provisionsAdmin/getUpdatedCarryForwardDtlById',
          //   'carry-forward',
          //   () => (this.loader = false),
          //   this.updateCarryForwardData
          // );
        }
      }
    });
  },
  methods: {
    checkUncheckAll() {
      this.updateCarryForwardData = this.updateCarryForwardData.map(data => {
        data.select = this.selectAllCheckBox;
        return data;
      });
    },
    getProvisonHdrById() {
      const payload = {
        cf_hdr_id: this.cfHdrId,
        requestPayload: {
          userId: this.$store.state.auth.userId
        }
      }
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisonHdrById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page[0];
            this.wfApprovalStatus = result.approval_status_key;
            this.approvalStatusKey = result.approval_status_key;
            this.totalCredit = result.credit;
            this.totalDebit = result.debit;
            if (result) {
              this.setFielsForProvision(result);
            }
            this.getUpdatedCarryForwardDtlById();
            if (this.approvalStatus === 'APPROVED') {
              this.getApprovedBatch();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setFielsForProvision(item) {
      this.legalEntity.text = item.le_name;
      this.legalEntity.value = item.erp_org_id;
      this.currency = item.currency;
      this.periodFrom = item.provision_period;
      this.periodTo = item.period_to;
      this.batchName = item.batch_name;
      this.approvalStatus = item.approval_status;
      this.addCarryForwardData = item.po_type_details;
      this.rowCount = item.line_count;
    },
    getUpdatedCarryForwardDtlById(download) {
      this.payload = {
        cf_hdr_id: this.cfHdrId,
        requestPayload: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
        }
      }
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getUpdatedCarryForwardDtlById', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.updateCarryForwardData = resp.data.data.page;
            this.updateTotalRows = resp.data.data.total_elements;
            const result = resp.data.data.page;
            if (download) {
              const customDownloadData = result.map((ele) => ({
                carry_fwd_hdr_id: ele.carry_fwd_hdrid,
                cf_dtl_id: ele.cf_dtl_id,
                le_id: ele.le_id,
                legal_entity: ele.le_name,
                vendor_name: ele.vendor_name,
                po_number: ele.po_number,
                line_number: ele.line_number,
                lob: ele.lob,
                cost_centre: ele.cost_centre,
                department: ele.department,
                account_code: ele.account_code,
                cf_period: ele.cf_period,
                cf_amount: ele.cf_amount
              }));
              commonHelper.downloadExcel(
                customDownloadData,
                'carry-forward'
              )
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setFieldsForDtl(item) {
      this.legalEntity.text = item.le_name;
      this.legalEntity.value = item.le_id;
      this.currency = item.currency;
      this.periodFrom = item.provision_period;
      this.periodTo = item.period_to;
      this.batchName = item.batch_name;
      this.approvalStatus = item.approval_status;
      this.addCarryForwardData = item.po_type_details;
    },
    getCarryForwardDetailData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          period_from: this.periodFrom,
          period_to: this.periodTo,
          vendor_name: this.vendorName.value,
          gl_posting_status: this.costCenter.text,
          account: this.concatenatedSegment.text,
          lob: this.lob,
          po_number: this.poNum,
          department: this.department
        };
        this.loader = true;
        this.$store
          .dispatch('provisionsAdmin/getCarryForwardDetailData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.addCarryForwardData = resp.data.page;
              this.totalRows = resp.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    addEditProvisionDetails() {
      if (this.legalEntity.text && this.periodFrom && this.periodTo && this.batchName) {
        const filterData = this.addCarryForwardData.filter(ele => {
          return !ele.po_type
            || !ele.state_code
            || !ele.cost_center
            || !ele.account_num
            || !ele.lob
            || !ele.company
            || !ele.department
            || !ele.vendor_name
        });
        if (filterData.length) {
          alert('Kindly Fill Details Mandatory Fields.')
        } else {
          const poTypeDetails = this.addCarryForwardData.map(elem => {
            return {
              prov_dtl_id: elem.prov_dtl_id ? elem.prov_dtl_id : 0,
              po_type: elem.po_type,
              po_distribution_id: elem.po_distribution_id,
              code_combination_id: elem.code_combination_id,
              // code_combination_segment: elem.code_combination_segment,
              concatenated_segments: elem.concatenated_segments,
              state_code: elem.state_code,
              cost_center: elem.cost_center,
              account_num: elem.account_num,
              sub_account_num: elem.sub_account_num,
              service_date: elem.service_date,
              lob: elem.lob,
              company: elem.company,
              department: elem.department,
              account_desc: elem.account_desc,
              amount: elem.amount,
              vendor_id: elem.vendor_id,
              vendor_code: elem.vendor_code,
              vendor_name: elem.vendor_name,
              po_number: elem.po_number,
              po_currency: elem.po_currency,
              po_line_number: elem.po_line_num,
              item_description: elem.item_description,
              exchange_rate: elem.exchange_rate,
              working_days: elem.working_days,
              extra_days: elem.extra_days,
              leave_encashment: elem.leave_encashment,
              accounting_date: elem.accounting_date,
              journal_name: elem.journal_name,
              journal_description: elem.journal_description,
              debit: elem.debit,
              credit: elem.credit,
              prov_acc_num: elem.prov_acc_num,
              expense_month: elem.expense_month,
              mis_4: elem.mis_4,
              mis_5: elem.mis_5
            };
          });
          const payload = {
            cf_hdr_id: this.cfHdrId,
            erp_org_id: this.legalEntity.value,
            le_name: this.legalEntity.text,
            currency: this.currency,
            period_from: this.periodFrom,
            period_to: this.periodTo,
            batch_name: this.batchName,
            approval_status: this.approvalStatus,
            line_count: this.rowCount,
            po_type_details: poTypeDetails ? poTypeDetails : null
          };
          this.loader = true;
          this.$store
            .dispatch('provisionsAdmin/addEditProvisionDetails', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                // this.arrayLengthFlag = 
                this.editMode = false;
                this.cfHdrId = response.data.data;
                this.getProvisonHdrById();
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.$emit('updateProvision');
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
      else {
        alert('Please Fill Header Mandatory Fields.')
      }
    },
    mapSelectedVsetData(item) {
      if (this.modalType === 'legal_entity') {
        this.legalEntity = {
          text: item.le_name,
          value: item.le_id
        };
      } else if (this.modalType === 'period') {
        this.periodFrom = item.period_name;
      } else if (this.modalType === 'periodTo') {
        this.periodTo = item.period_name;
      } else if (this.modalType === 'expenseMonth') {
        this.cfPeriod = item.period_name;
      } else if (this.modalType === 'vendor') {
        this.vendorName.value = item.vendor_code;
        this.vendorName.text = item.vendor_name;
      } else if (this.modalType === 'po_number') {
        this.poNum = item.po_number;
        // this.getAccCodeDetails()
      } else if (this.modalType === 'account_code') {
        // this.po_distribution_id = item.po_distribution_id;
        this.concatenatedSegment.value = item.code_combination_id;
        this.concatenatedSegment.text = item.concatenated_segments;
        // this.concatenated_segments = item.concatenated_segments;
      } else if (this.modalType === 'cost_center') {
        this.costCenter = item.cost_center;
      } else if (this.modalType === 'lob') {
        this.lob = item.lob;
      }
      this.addCarryForwardData = JSON.parse(
        JSON.stringify(this.addCarryForwardData)
      );
      this.showHideCommonModal(false);
    },
    // newFunction() {
    //   if (this.addCarryForwardData[this.segmentIndex].state_code && this.addCarryForwardData[this.segmentIndex].cost_center && this.addCarryForwardData[this.segmentIndex].account_num && this.addCarryForwardData[this.segmentIndex].sub_account_num.toString()) {
    //     this.getLobCompanyDepartmentDetails();
    //   }
    // },
    showHideCommonModal(flag, type, index) {
      this.modalType = type;
      this.specIndex = index;
      if (flag) {
        if (this.modalType === 'po_number') {
          this.vendorCode = this.vendorName.value;
        } else if (this.modalType === 'account_code') {
          this.poNumber = this.poNum;
        } else if (this.modalType === 'periodTo') {
          this.periodTo = this.periodTo;
        } else if (this.modalType === 'expenseMonth') {
          this.expenseMonth = this.cfPeriod;
        } else if (this.modalType === 'cost_center') {
          this.stateCode = this.state_code;
        } else if (this.modalType === 'lob') {
          this.lob = this.lob;
        }
      }
      this.showCommonModal = flag;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'legal_entity':
          this.legalEntity = this.defaultValue;
          break;
        case 'period':
          this.periodFrom = null;
          break;
        case 'periodTo':
          this.periodTo = null;
          break;
        case 'vendor':
          this.vendorName.text = null;
          this.vendorName.value = null;
          this.po_number = null;
          this.po_currency = null;
          this.po_line_num = null;
          this.item_description = null;
          break;
        case 'po_number':
          this.poNum = null;
          break;
        case 'account_code':
          this.concatenatedSegment.text = null;
          this.concatenatedSegment.value = null;
          break;
        case 'cost_center':
          this.costCenter.text = null;
          this.costCenter.value = null;
          this.department = null;
          this.lob = null;
          break;
        case 'expenseMonth':
          this.cfPeriod = null;
          break;
        case 'lob':
          this.lob = null;
          break;
      }
    },
    // showHideApproveRejectModal(flag, type) {
    //   this.showApproveRejectModal = flag;
    //   this.approvRejectType = type;
    // },
    // approveRejectProvisons(status) {
    //   const payload = {
    //     event_id: this.provRowDetails ? this.provRowDetails.wf_event_id : this.wfProvisionDetails ? this.wfProvisionDetails.wf_event_id : null,
    //     wf_inprocess_dtl_id: this.provRowDetails ? this.provRowDetails.wf_inprocess_dtl_id : this.wfProvisionDetails ? this.wfProvisionDetails.wf_inprocess_dtl_id : null,
    //     notification_status: status,
    //     notification_comments:
    //       status === 'REJECTED' ? this.rejectedReason : this.approvalReason
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/approvalRejectSingle', payload)
    //     .then(response => {
    //       this.loader = false;
    //       this.showAlert = true;
    //       if (response.status === 200) {
    //         // this.getVendorInvoiceDetailsById();
    //         this.editMode = false;
    //         this.isSuccess = true;
    //         this.responseMsg = response.data.message;
    //         if (status === 'REJECTED') {
    //           this.showHideApproveRejectModal(false);
    //         } else if (status === 'APPROVED') {
    //           this.showHideApproveRejectModal(false);
    //         }
    //         this.getProvisonHdrById();
    //         // this.$emit('updateInvoice');
    //       } else {
    //         this.showAlert = false;
    //         alert(response.response.data.message);
    //         // this.isSuccess = false;
    //         // this.responseMsg = response.response.data.message;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // getApprovedBatch() {
    //   const payload = {
    //     prov_hdr_id: this.provRowDetails ? this.cfHdrId : this.wfProvisionDetails ? this.cfHdrId : null,
    //   }
    //   this.loader = true;
    //   this.$store
    //     .dispatch('provisionsAdmin/getApprovedBatch', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.filePath = resp.data.data.file_path;
    //         this.ouName = resp.data.data.ou_name;
    //         this.requestName = resp.data.data.request_name;
    //         this.respName = resp.data.data.resp_name;
    //         this.successMsg = resp.data.data.success_msg;
    //         this.provisionSubmitRequest();
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // provisionSubmitRequest(){
    //   const payload = {
    //     InputParameters: [
    //       {
    //         REQUEST_NAME: this.requestName,
    //         OU_NAME: this.ouName,
    //         USER_NAME: "",
    //         RESP_NAME: this.respName,
    //         ARGUMENT1: this.filePath,
    //         ARGUMENT2: "",
    //         ARGUMENT3: "",
    //         ARGUMENT4: "",
    //         ARGUMENT5: "",
    //         ARGUMENT6: "",
    //         ARGUMENT7: "",
    //         ARGUMENT8: "",
    //         ARGUMENT9: "",
    //         ARGUMENT10: "",
    //         ARGUMENT11: "",
    //         ARGUMENT12: "",
    //         ARGUMENT13: "",
    //         ARGUMENT14: "",
    //         ARGUMENT15: ""
    //       }
    //     ]
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('provisionsAdmin/provisionSubmitRequest', payload)
    //     .then(response => {
    //       this.loader = false;
    //       this.showAlert = true;
    //       if (response.status === 200) {
    //         this.editMode = false;
    //         this.isSuccess = true;
    //         this.responseMsg = response.data.message;
    //       } else {
    //         this.showAlert = false;
    //         this.isSuccess = false;
    //         this.responseMsg = response.response.data.message;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    getAccCodeDetails() {
      const payload = {
        _page: 0,
        _limit: 100,
        po_num: this.poNum,
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccCodeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            const accSegment = result[0].concatenated_segments;
            const splitSegment = accSegment.split('.');
            this.addCarryForwardData[this.segmentIndex].company = splitSegment[0];
            // this.addCarryForwardData[this.segmentIndex].lob = splitSegment[1];
            // this.addCarryForwardData[this.segmentIndex].department = splitSegment[2];
            // this.addCarryForwardData[this.segmentIndex].state_code = splitSegment[3];
            // this.addCarryForwardData[this.segmentIndex].cost_center = splitSegment[4];
            // this.addCarryForwardData[this.segmentIndex].account_num = splitSegment[5];
            // this.addCarryForwardData[this.segmentIndex].sub_account_num = splitSegment[6];
            // this.addCarryForwardData[this.segmentIndex].po_distribution_id = null;
            // this.getAccNoDetails(result[0].po_distribution_id);
          }
          this.addCarryForwardData = JSON.parse(JSON.stringify(this.addCarryForwardData))

        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAccNoDetails(poDistributionId) {
      const payload = {
        _page: 0,
        _limit: 100,
        account_num: this.addCarryForwardData[this.segmentIndex].account_num,
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccNoDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            const isIdPresent = this.addCarryForwardData.some(obj => obj.po_distribution_id == poDistributionId);
            if (!isIdPresent) {
              this.addCarryForwardData[this.segmentIndex].po_distribution_id = poDistributionId;
              this.addCarryForwardData[this.segmentIndex].account_desc = result[0].account_desc;
            } else {
              alert('Distribution id already selected');
              this.addCarryForwardData[this.segmentIndex].po_number = null,
                this.addCarryForwardData[this.segmentIndex].po_currency = null,
                this.addCarryForwardData[this.segmentIndex].po_line_num = null,
                this.addCarryForwardData[this.segmentIndex].item_description = null,
                this.addCarryForwardData[this.segmentIndex].account_num = null;
              this.addCarryForwardData[this.segmentIndex].sub_account_num = null;
              this.addCarryForwardData[this.segmentIndex].account_desc = null;
              this.addCarryForwardData[this.segmentIndex].company = null,
                this.addCarryForwardData[this.segmentIndex].lob = null,
                this.addCarryForwardData[this.segmentIndex].department = null,
                this.addCarryForwardData[this.segmentIndex].state = null,
                this.addCarryForwardData[this.segmentIndex].cost_centre = null
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLobCompanyDepartmentDetails() {
      const payload = {
        state_code: this.addCarryForwardData[this.segmentIndex].state_code,
        cost_center: this.addCarryForwardData[this.segmentIndex].cost_center,
        // account_number: this.addCarryForwardData[this.segmentIndex].account_num,
        // subaccount_number: this.addCarryForwardData[this.segmentIndex].sub_account_num
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getLobCompanyDepartmentDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.addCarryForwardData[this.segmentIndex].company = result[0].company;
            this.addCarryForwardData[this.segmentIndex].lob = result[0].lob;
            this.addCarryForwardData[this.segmentIndex].department = result[0].department;
          }
          this.addCarryForwardData = JSON.parse(JSON.stringify(this.addCarryForwardData))
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getProvisionAccountMappingData(accNum, index) {
      const payload = {
        account_num: accNum,
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisionAccountMappingData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.page;
            this.addCarryForwardData[index].prov_acc_num = result[0].prov_acc_num;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.periodFrom = null;
      this.periodTo = null;
      this.vendorName = this.defaultValue;
      this.poNum = null;
      this.costCenter = this.defaultValue;
      this.concatenatedSegment = this.defaultValue;
      this.lob = null;
      this.department = null;
      this.carryForwardData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};